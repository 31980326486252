import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import { Link } from "@reach/router"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import draftToHtml from "draftjs-to-html"
import moment from "moment"

export default function Post({ data }) {
  const intl = useIntl()
  const locale = intl.locale !== "id" ? `/${intl.locale}` : ""
  const { value, title, created_at, imageUrl } = data.article

  return (
    <div className={"page"}>
      <div className={"img-bg"}>
        <Layout>
          <SEO title={`${title}`} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className={"row float-right"}>
                <Link to={`${locale}/articles`}>
                  <Button variant="secondary" className={"rounded-pill"}>
                    <FontAwesomeIcon icon={faArrowCircleLeft} />{" "}
                    {intl.formatMessage({ id: "btn-back" })}
                  </Button>
                </Link>
              </div>
              <div className="shadow segment-table my-4 bg-white p-4">
                <br />
                <h2 className="my-1 txt-primary text-center mb-4">
                  <b style={{ color: "#ad1457" }}>{`${title}`}</b>
                </h2>
                <br />
                <div className={"float-left"}>
                  <FontAwesomeIcon
                    style={{ color: "#ad1457" }}
                    icon={faCalendarAlt}
                  />{" "}
                  {moment(created_at).format("dddd, MMMM D YYYY")}
                </div>
                <br />
                <br />
                <img
                  src={`${imageUrl}`}
                  className="rounded"
                  alt="..."
                  height={400}
                  width={"100%"}
                  style={{ objectFit: "cover" }}
                />
                <br />
                <div
                  className={"mb-4"}
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(JSON.parse(value)),
                  }}
                />
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query ArticleContentQuery($title_slug: String!) {
    article(title_slug: { eq: $title_slug }) {
      title
      imageUrl
      pathUrl
      title_slug
      page_slug
      value
      created_at
    }
  }
`
